// JS Script
// https://github.com/uvarov-frontend/vanilla-calendar
// https://vanilla-calendar.com/
import VanillaCalendar from '@uvarov.frontend/vanilla-calendar';

const events = {
	ui: {},

	init: function() {
		this.initCalendar();
        this.handleHoverOnCalendar();
        this.toggleOnMobile();
        this.handleSeeMore();
	},

    initCalendar: function() {
        let that = this;

        const items = document.querySelector('.js-events__items').querySelectorAll('.js-eventItem');
        let selected_month = null;
        if(items.lenght){
            const firstEventDate = items[0].getAttribute('data-date'); // assume format = 'YYYY-MM-DD' ex : data-date="2023-10-01"
            const firstEventDate__array = firstEventDate.split("-");
            selected_month = parseInt(firstEventDate__array[1]-1); // (month = month-1) because JS is a weird kid
        }

        this.ui.eventFormat = document.querySelector('.js-events-calendar').getAttribute('eventFormat');
        // console.log(this.ui.eventFormat);
        this.ui.calendar = new VanillaCalendar('.js-events-calendar', {
            settings: {
                // selection: {
                //     day: true,
                //     month: true,
                //     year: true,
                // },
                selected: {
                    // month: selected_month,
                    // dates: this.getEventsDates(),
                },
            },
            actions: {
                selection: {
                    day: false,
                    year: false,
                },
                clickDay(event, dates) {
                    console.log(event);
                    that.loadEvents(dates);
                },
                clickArrow(event, year, month) {
                    // console.log(event, year, month);
                    that.loadEvents();
                },
                clickMonth(e, month) {
                    // console.log(e, month);
                    that.loadEvents();
                },
                clickYear(event, year) {
                    // console.log(event, year);
                    that.loadEvents();
                },
            },
        });
        if(selected_month){
            this.ui.calendar.settings.selected.month = selected_month;
        }

        this.ui.calendar.init();
        that.getEventsDates();

    },
    loadEvents: function(dates){
        let that = this
        $("html").addClass("loading");
    
        if(window.currentajax){// si requete en cours, on l'annule et la remplace par la nouvelle
            window.currentajax.abort();
        }
        let selectedMonth = that.ui.calendar.selectedMonth;
        let month = that.ui.calendar.selectedMonth+1
        month = (month < 10) ? "0"+month : month;
        let nextMonth = that.ui.calendar.selectedMonth+2
        nextMonth = (nextMonth < 10) ? "0"+nextMonth : nextMonth;
        let year = that.ui.calendar.selectedYear;
        let nextYear = that.ui.calendar.selectedYear;
        if(nextMonth > 12){
            nextMonth = "01";
            nextYear = nextYear+1;
        }
        let params = {};
        if(dates && dates.length){
            console.log(dates);
            console.log('do nothing');
            return;
            // let dayStart = dates[0]; // ex : ['2023-12-31']
            // let dayStart__array = dayStart.split('-');
            // console.log(dayStart__array);
            // params = {
            //     'orderby' : 'meta_value',
            //     'meta_query' : [
            //         {
            //             'relation': 'AND'
            //         },
            //         {
            //             'type': 'DATE',
            //             'key' : 'event_start_date',
            //             'value' : year + "-"+ month + "-" + dayStart__array[2] + " " + "00:00:00",
            //             'compare' : '>='
            //         },
            //         {
            //             'type': 'DATE',
            //             'key' : 'event_start_date',
            //             'value' : year + "-"+ month + "-" + dayStart__array[2] + " " + "12:00:00",
            //             'compare' : '<'
            //         }
            //     ]
            // }
            console.log(params);
        }else{
            /*
                Quand je slide sur les mois
                - Evenement dont la date de fin est > à la date du début de mois
                - Date de début <= date de fin du mois
            */
            params = {
                'orderby' : 'meta_value',
                'meta_query' : [
                    {
                        'relation': 'AND'
                    },
                    {//  Evenement dont la date de fin est > à la date du début de mois
                        'type': 'DATE',
                        'key' : 'event_end_date',
                        'value' : year + "-"+ month + "-" + "01" + " " + "00:00:00",
                        'compare' : '>='
                    },
                    {// Date de début <= date de fin du mois 
                        'type': 'DATE',
                        'key' : 'event_start_date',
                        'value' : nextYear + "-"+ nextMonth + "-" + "01" + " " + "00:00:00",
                        'compare' : '<'
                    }
                ]
            }
        }
        params.eventFormat = that.ui.eventFormat;
        let cat = $(".js-events__items").attr('data-cat');
        if(cat){
            params.category_name = cat;
        }
        // post or get
        window.currentajax = $.ajax({
            method: "POST",
            url: wordpress_vars.ajax_url,
            data: {
                'action': 'get_events',
                'params': params
            }
        }).done(function(response) {
            $(".js-events__items").html(response);
            $('.js-events__pagination').remove();
            that.getEventsDates();
            // that.ui.calendar.settings.selected.dates = that.getEventsDates();
            // that.ui.calendar.selectedMonth = selectedMonth;
            // that.ui.calendar.update();
        })
        .fail(function(response) {
            if(response.statusText != 'abort'){
                console.error(response);
            }
        })
        .always(function(response) {
            $("html").removeClass("loading");

            const container = document.querySelector('.js-events__items');

            if (container) {
                container.classList.remove('--limited')
                let $events__more = document.querySelector('.events__more')
                if($events__more){
                    $events__more.classList.add('--hided');
                }
            }
        });
    },

    getEventsDates: function() {
        const items = document.querySelector('.js-events__items').querySelectorAll('.js-eventItem');
        const itemsDates = new Set();
        items.forEach(item => {  
            const date = item.dataset.date;
            if (date) {
                itemsDates.add(date);
            }
        });
        let $days = document.querySelectorAll('.vanilla-calendar-day__btn');
        $days.forEach(item => {  
            const date = item.dataset.date;
            if(itemsDates.has(item.getAttribute('data-calendar-day'))){
                item.classList.add('active');
            }
        });
        this.ui.selectedEventsDates = itemsDates;
        return itemsDates;
    },

    setClassOnItems: function(type, exception) {
        const items = document.querySelector('.js-events__items').querySelectorAll(`.js-eventItem:not(${exception})`);
        items.forEach(item => {
            if (type === "add") {
                item.classList.add('--not-active')
            } else {
                item.classList.remove('--not-active')
            }
        });
    },

    handleHoverOnCalendar: function() {
        let that = this;
        $("body").on('mouseover', '.active', function(){
            let date = $(this).attr('data-calendar-day');
            let eventItem = `[data-date="${date}"]`;
            that.setClassOnItems('add', eventItem);
        });
        $("body").on('mouseleave', '.active', function(){
            let date = $(this).attr('data-calendar-day');
            let eventItem = `[data-date="${date}"]`;
            that.setClassOnItems('remove', eventItem);
        });
    },

    toggleOnMobile: function() {

        const btn = $('.js-show-calendar');
        const toggleText = btn.data('toggle-text');
        const baseText = btn.text()

        btn.on('click', function(e){
            e.preventDefault();

            $('.js_events-calendar-toggle').slideToggle()
            btn.toggleClass('--active');

            if (btn.hasClass('--active')) {
                btn.html(toggleText)
            } else {
                btn.html(baseText)
            }
        })

    },

    handleSeeMore: function(){

        const btnSeeMore = document.querySelector('.js-events-more')
        
        if (!btnSeeMore) return;

        const currentText = btnSeeMore.textContent;
        const altText = btnSeeMore.dataset.altText;

        btnSeeMore.addEventListener('click', (e) => {
            e.preventDefault()
            const container = document.querySelector('.js-events__items');

            if (container) {
                
                if (btnSeeMore.classList.contains('--active')) {
                    btnSeeMore.classList.remove('--active')
                    container.classList.add('--limited')
                    btnSeeMore.textContent = currentText;
                } else {
                    btnSeeMore.classList.add('--active')
                    container.classList.remove('--limited')
                    btnSeeMore.innerText = altText;
                }

            }
        })

    }

};

module.exports = events;
